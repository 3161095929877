import React from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
import { MakeReview } from 'components/reviews'
import { useQueryParam, StringParam } from 'use-query-params'
import SEO from 'components/seo'

function MakeReviewPage() {
  const [orderId] = useQueryParam('order-id', StringParam)

  return (
    <div className='container'>
      <SEO
        title='Оставить отзыв'
      />
      <div className={css.makeReviewPage}>
        <div className={css.title}>
          <h2>Оставить отзыв</h2>
        </div>

        <p>Пожалуйста, оставьте отзыв о полученном наборе.</p>

        <MakeReview
          orderId={orderId}
        />
      </div>
    </div>
  )
}

export default withMainLayout(MakeReviewPage)